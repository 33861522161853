import * as React from "react";
import { HtmlHead, Link } from "@cdv/jazz-web/components";
import { Typography, Box, Container } from "@mui/material";
import { StaticImage } from "gatsby-plugin-image";
import Layout from "../components/Layout";

export const Head = () => (
  <HtmlHead title="Základní údaje | Centrum dopravního výzkumu, v. v. i.">
    <meta http-equiv="X-UA-Compatible" content="IE=edge" />
    <meta
      name="description"
      content="Výzkum podmínek a návrh opatření pro omezení počtu a zmírnění následků neoprávněných vstupů chodců do prostoru dráhy "
      lang="cs"
    />
    <meta
      name="keywords"
      content="Výzkum, podmínky, návrh, opatření, počtu, následky, neoprávněných vstupů, chodci, dráhy "
      lang="cs"
    />
    <meta name="robots" content="index, follow" />
    <meta name="rating" content="general" />
    <meta name="viewport" content="width=device-width, initial-scale=1.0" />
  </HtmlHead>
);

export default function Page() {
  return (
    <Layout>
      <Container>
        <Typography variant="h2">
          Výzkum podmínek a návrh opatření pro omezení počtu a zmírnění následků neoprávněných
          vstupů chodců do prostoru dráhy{" "}
        </Typography>

        <Typography variant="body1">
          Projekt byl řešen v letech 2014 – 2017 za finanční spoluúčasti Technologické agentury
          České republiky v rámci programu ALFA jako <strong>TA04030327</strong>
        </Typography>

        <Typography variant="h2">Řešitelé projektu</Typography>

        <Box>
          <Box>
            <strong>Centrum dopravního výzkumu, v. v. i. </strong>
            <br />
            <br />
            <ul>
              <li>
                <Link to="mailto:pavlina.skladana@cdv.cz">Mgr. Pavlína Skládaná</Link>,{" "}
              </li>
              <li>Ing. Pavel Skládaný,</li>
              <li> Ing. Pavel Tučka, </li>
              <li> Ing. Miroslav Bidovský,</li>
              <li> Ing. Jan Perůtka,</li>
              <li> Mgr. Barbora Sulíková, </li>
              <li> Mgr. Michal Šimeček, Ph.D.,</li>
              <li> Mgr. Richard Andrášik,</li>
              <li> Mgr. Jan Kubeček,</li>
              <li> Mgr. Jiří Sedoník</li>
            </ul>
          </Box>

          <Box>
            <strong>AK signal Brno a.s. </strong>
            <br />
            <br />
            <ul>
              <li>
                <Link to="mailto:ladislav.polcar@aksignal.cz">Ing. Ladislav Polcar</Link>,
              </li>
              <li>Ing. Jiří Šanda</li>
            </ul>
          </Box>
        </Box>

        <Typography variant="body1">
          Projekt AMELIA se zabýval vztahem mezi protiprávním jednáním chodců a cyklistů
          (nelegálními vstupy do kolejiště, tzv. trespassingem) a charakteristikami lokalit, kde k
          tomuto jednání dochází. Získané znalosti byly zpracovány, kategorizovány a využity k
          definici účinných preventivních opatření.
        </Typography>

        <Typography variant="body1">&nbsp;</Typography>
      </Container>
    </Layout>
  );
}
